.min-width-5 {
	min-width: 5rem;
}

.top-0 {
	top: 0;
}

.right-0 {
	right: 0;
}

@include media-breakpoint-up(sm) {
	.bg-sm-table-accent {
		background-color: $table-accent-bg;
	}
}