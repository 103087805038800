//////////////////////////////////////////////////
// Root styles
//////////////////////////////////////////////////

html {
	font-size: $font-size-root-xs;

	@include media-breakpoint-up(md) {
		font-size: $font-size-root-md;
	}
}


//////////////////////////////////////////////////
// Headings
//////////////////////////////////////////////////

.display-5 {
  font-size: $display5-size;
  font-weight: $display5-weight;
  line-height: $display-line-height;
}

.display-6 {
  font-size: $display6-size;
  font-weight: $display6-weight;
  line-height: $display-line-height;
}

.lined-heading {
  margin-bottom: $spacer * 2;
  padding-bottom: $spacer / 2;
  border-bottom: $border;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.text-heading {
  font-family: $headings-font-family;
}

//////////////////////////////////////////////////
// Style helpers
//////////////////////////////////////////////////

.max-read-width {
  max-width: $max-read-width;
}

.strong {
	font-weight: bolder;
}

.em {
	font-style: italic;
}

.font-weight-inherit {
	font-weight: inherit;
}

.large {
	font-size: 125%;
}

.xlarge {
	font-size: 150%;
}

.line-height-1 {
	line-height: 1;
}

.text-break {
  word-break: break-all;
}

.text-pre-line {
  white-space: pre-line;
}

.text-nowrap {
	white-space: nowrap;
}

.reduced-em {
  opacity: 0.6;
}
