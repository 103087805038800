svg {
  display: inline-block;
  width: $icon-size;
  height: $icon-size;
  fill: currentColor;
  vertical-align: middle;

  // Prevent the SVG title from displaying on hover
  &:not(.icon-has-title) * {
    pointer-events: none;
  }
}
