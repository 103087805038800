//------------------------------------------------
// Backgrounds
//------------------------------------------------

@mixin bg-texture($color, $opacity: 0.7, $bg: null, $bg-size: null) {
	background:
		$bg,
		linear-gradient(
			rgba($color, $opacity), 
			rgba($color, $opacity)
		),
		$color url($noise) top left repeat;
	background-size:
		$bg-size,
		100px 100px;
}

@mixin bg-slash($color) {
	background:
		repeating-linear-gradient(
			295deg,
			$color,
			$color 2px,
			transparent 2px,
			transparent 6px
		);
}

@mixin bg-dot($color) {
	background-image:
		radial-gradient($color 30%, transparent 30%),
		radial-gradient($color 30%, transparent 30%);
	background-size: 8px 8px;
}